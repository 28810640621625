@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
}
body {
  @apply h-full;
}

#root {
  @apply h-full;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ad8df2;
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6334cc;
}
