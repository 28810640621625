.container::-webkit-scrollbar {
  width: 3px;
}

.container::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.container::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
  cursor: pointer;
}
